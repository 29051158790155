<template>
  <div>
    <c-card :title="'\'' + popupParam.vendorName + '\' 실적 엑셀업로드'" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="업체"
            name="vendorName"
            v-model="popupParam.vendorName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <c-text
            :editable="editable"
            :disabled="true"
            label="정산월"
            name="settlementMonth"
            v-model="popupParam.settlementMonth">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'monthly-performance-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          vendorName: '',
          settlementMonth: '',
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['smfMonthlyPerformanceItemResultId'], // 데이터들의 키 속성값
        taskClassCd: 'MONTHLY_PERFORMANCE', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code 
    this.$comm.getComboItems('SMF_ITEM_CD').then(_result => {
        this.excelUploadInfo.columns.push([
          {
            name: 'smfItemCd',
            label: 'LBLITEMS',
            type: 'select',
            required: true,
            style: 'width:350px',
            align: 'center',
            comboItems: _result
          },
          {
            name: 'settlementDate',
            label: '사용일',
            type: 'date',
            required: true,
            style: 'width:120px',
            align: 'center',
            start: this.start,
            end: this.end,
          },
          {
            name: 'detailItemName',
            label: '품목',
            style: 'width:200px',
            align: 'left',
          },
          {
            name: 'standard',
            label: '규격',
            style: 'width:80px',
            align: 'left',
          },
          {
            name: 'unit',
            label: 'LBLUNIT',
            style: 'width:70px',
            align: 'left',
          },
          {
            name: 'quantity',
            label: 'LBLAMOUNT',
            required: true,
            style: 'width:70px',
            align: 'right',
            type: 'number'
          },
          {
            name: 'unitPrice',
            label: '단가',
            required: true,
            style: 'width:70px',
            align: 'right',
            type: 'number'
          },
          {
            name: 'amount',
            label: '금액',
            required: true,
            style: 'width:100px',
            align: 'right',
            type: 'number'
          },
          {
            name: 'vat',
            label: '부가세',
            style: 'width:70px',
            align: 'right',
            type: 'number'
          },
        ])
      });
      // list setting
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            this.$emit('closePopup', this.excelUploadInfo.data[0]);
          }
        }
      }
    }
  }
};
</script>
